<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none">
    <g>
      <path
        d="M8.5 10.9489V8.11556M8.5 5.28223H8.50708M15.5833 8.11556C15.5833 12.0276 12.412 15.1989 8.5 15.1989C4.58798 15.1989 1.41667 12.0276 1.41667 8.11556C1.41667 4.20354 4.58798 1.03223 8.5 1.03223C12.412 1.03223 15.5833 4.20354 15.5833 8.11556Z"
        :stroke="color"
        stroke-width="1.41667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 17 16" },
    width: { type: String, default: "17" },
    height: { type: String, default: "16" },
    color: { type: String, default: "#4C6EF3" },
  },
};
</script>
