<template>
  <span
    class="label__info"
    v-if="info"
    @mouseenter.prevent="mouseenter"
    @mouseleave.prevent="mouseleave"
  >
    <IconInfo />
    <span v-show="show" class="label__info-text">{{ info }}</span>
  </span>
</template>

<script>
import IconInfo from "@/components/icons/IconInfo.vue";

export default {
  components: {
    IconInfo,
  },
  props: {
    info: { type: String, default: "" },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    mouseenter() {
      this.show = true;
    },
    mouseleave() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.label__info {
  position: relative;
  cursor: pointer;
  &-text {
    @include type($fw: 500);
    color: $lightBlack;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, -100%);
    padding: 1.1rem;
    width: max-content;
    max-width: 40rem;
    box-shadow: 0 2px 12px rgba($color: $black, $alpha: 0.25);
    border: 0.5px solid $lightGrey;
    border-radius: 12px;
    background-color: $white;
  }
}
</style>
